import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SingleInvestment from 'components/Investments/SingleInvestment'
import Button from 'components/shared/Button'
import Content from 'components/shared/Content'
import Slider from 'components/Slider/Slider'
import { LangContext } from 'contexts/LangContext'

const Wrapper = styled.div`
  padding-top: 20px;
  ${({ theme }) => theme.mq.md} {
    padding-top: 50px;
  }
`

const Filters = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding-bottom: 30px;
  & > button {
    white-space: nowrap;
    width: 100%;
    ${({ theme }) => theme.mq.lg} {
      width: auto;
    }
  }
  ${({ theme }) => theme.mq.md} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${({ theme }) => theme.mq.lg} {
    display: flex;
    justify-content: flex-start;
    & > button {
      margin: 0 5px 0 0;
    }
  }
`

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px 0;
  ${({ theme }) => theme.mq.s} {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  ${({ theme }) => theme.mq.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${({ theme }) => theme.mq.xl} {
    gap: 60px;
  }
`

const SliderWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mq.s} {
    display: block;
  }
`

const SlideWrapper = styled.div`
  /* border-left: 10px solid transparent;
  border-right: 10px solid transparent; */
`

const Experience = ({ projects }) => {
  const [activeFilter, setActiveFilter] = useState('in progress')
  const [filteredItems, setFilteredItems] = useState(projects)
  const { lang } = useContext(LangContext)

  const handleFilters = filter => {
    const filtered = projects.filter(({ status }) => status === filter)

    setFilteredItems(filtered)
    setActiveFilter(filter)
  }

  const allStatuses = projects
    .map(({ status }) => status)
    .filter(status => status)

  const filters = [...new Set(allStatuses)]

  useEffect(() => {
    setFilteredItems(projects)
    setActiveFilter('in progress')
    handleFilters('in progress')
  }, [lang])

  const slides = filteredItems.map(
    ({
      id,
      image,
      imageAlt,
      city,
      district,
      name,
      description,
      link,
      isNew,
    }) => (
      <SlideWrapper key={id}>
        <SingleInvestment
          image={image}
          imageAlt={imageAlt}
          localization={district ? `${city} - ${district}` : city}
          name={name}
          description={description}
          link={link}
          isNew={isNew}
          type="experience"
        />
      </SlideWrapper>
    )
  )

  // const settings = {
  //   slidesToShow: slides.length < 3 ? slides.length : 3,
  //   speed: 500,
  //   responsive: [
  //     {
  //       breakpoint: 1000,
  //       settings: {
  //         slidesToShow: slides.length < 2 ? slides.length : 2,
  //       },
  //     },
  //     {
  //       breakpoint: 650,
  //       settings: {
  //         slidesToShow: 1,
  //         centerMode: true,
  //       },
  //     },
  //   ],
  // }

  return (
    <Wrapper>
      <Content>
        <Filters>
          {filters.map(filter => (
            <Button
              key={filter}
              onClick={() => handleFilters(filter)}
              isActive={activeFilter === filter}
              outline
              secondary="true"
            >
              {filter.toLowerCase() === 'completed' &&
                lang === 'DE' &&
                'Abgeschlossen'}
              {filter.toLowerCase() === 'in progress' &&
                lang === 'DE' &&
                'Im Bau'}
              {filter.toLowerCase() === 'completed' && lang === 'EN' && filter}
              {filter.toLowerCase() === 'in progress' &&
                lang === 'EN' &&
                filter}
            </Button>
          ))}
        </Filters>
        <ItemsWrapper>{slides}</ItemsWrapper>
      </Content>
      {/* <SliderWrapper>
        <Slider
          arrowsPosition="25%"
          carousel
          items={slides}
          settings={settings}
        />
      </SliderWrapper> */}
    </Wrapper>
  )
}

Experience.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Experience
