/* eslint-disable no-useless-escape */
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Heading from 'components/shared/Heading'
import LazyImage from 'components/shared/LazyImage'
import useMedia from 'hooks/useMedia'
import { LangContext } from 'contexts/LangContext'
import triangleSvg from 'assets/icons/triangle.svg'
import triangleWhiteSvg from 'assets/icons/triangle-white.png'

const Wrapper = styled.address`
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${({ theme, red }) => (red ? theme.colors.white : theme.colors.dark)};
  background-color: ${({ theme, red }) =>
    red ? theme.colors.red : theme.colors.white};
  ${({ theme }) => theme.mq.s} {
    flex-direction: row;
  }
  ${({ theme }) => theme.mq.xl} {
    max-height: 450px;
  }
  &:before {
    position: absolute;
    width: 100px;
    height: 100px;
    left: -9px;
    bottom: -60px;
    background: url(${triangleSvg}) no-repeat center;
    ${({ red }) =>
      !red &&
      css`
        background-image: url(${triangleWhiteSvg});
      `}
    ${({ theme }) => theme.mq.s} {
      content: '';
    }
  }
`

const Padding = styled.div`
  padding-bottom: 25px;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  ${({ theme }) => theme.mq.s} {
    flex-basis: 120%;
    padding: 30px 40px;
  }
`

const Detail = styled.p`
  display: block;
  padding: 15px 0;
  white-space: pre-wrap;
  color: ${({ theme, red }) => (red ? theme.colors.white : theme.colors.dark)};
  text-decoration: none;
  font-size: 1.8rem;
  line-height: 22px;
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme }) => theme.fontSize.lg};
    line-height: 26px;
    padding: 15px 0;
  }
`

const Link = styled(Detail)`
  padding: 0;
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Card = ({ city, name, image, imageAlt, localization, email, phone }) => {
  const matchesS = useMedia('(min-width: 576px)')
  const imageHeight = matchesS ? 450 : 250
  const { lang } = useContext(LangContext)

  return (
    <Wrapper red={!city}>
      <Content>
        {city && (
          <Padding>
            <Heading highlight={city} small />
          </Padding>
        )}
        <Heading nouppercase={Boolean(city)} title={name} small />
        {localization.map(text => (
          <React.Fragment key={text + lang}>
            {text && (
              <Detail
                red={!city}
                dangerouslySetInnerHTML={{
                  __html: text.replace(/<br\s*[\/]?>/gi, ''),
                }}
              />
            )}
          </React.Fragment>
        ))}
        <Link
          red={!city}
          as="a"
          href={`mailto:${email}`}
          dangerouslySetInnerHTML={{ __html: email }}
        />
        <Link
          red={!city}
          as="a"
          href={`tel:${phone}`}
          dangerouslySetInnerHTML={{ __html: phone }}
        />
      </Content>
      <ImageWrapper>
        <LazyImage cover height={imageHeight} src={image} alt={imageAlt} />
      </ImageWrapper>
    </Wrapper>
  )
}

Card.propTypes = {
  city: PropTypes.string,
  name: PropTypes.string.isRequired,
  localization: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
}

Card.defaultProps = {
  city: '',
}

export default Card
