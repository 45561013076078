import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SingleInvestment from 'components/Investments/SingleInvestment'
import Button from 'components/shared/Button'
import { LangContext } from 'contexts/LangContext'

const Wrapper = styled.div`
  padding-top: 30px;
  ${({ theme }) => theme.mq.s} {
    padding-top: 50px;
  }
`

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px 0;
  ${({ theme }) => theme.mq.s} {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  ${({ theme }) => theme.mq.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${({ theme }) => theme.mq.xl} {
    gap: 60px;
  }
`

const Text = styled.p`
  font-size: 1.8rem;
  padding: 15px 0 0;
`

const Filters = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 7px;
  padding-bottom: 30px;
  @media (min-width: 375px) {
    grid-template-columns: repeat(2, 1fr);
  }
  & > button {
    white-space: nowrap;
    width: 100%;
    ${({ theme }) => theme.mq.lg} {
      width: auto;
    }
  }
  ${({ theme }) => theme.mq.md} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${({ theme }) => theme.mq.lg} {
    display: flex;
    justify-content: flex-start;
    & > button {
      margin: 0 5px 0 0;
    }
  }
`

const Investments = ({ investments }) => {
  const { lang } = useContext(LangContext)

  let allText = lang === 'EN' && 'All'
  allText = lang === 'DE' ? 'Alles' : allText

  const [activeFilter, setActiveFilter] = useState([allText])
  const [filteredItems, setFilteredItems] = useState(investments)

  useEffect(() => {
    setActiveFilter([allText])
    setFilteredItems(investments)
  }, [investments])

  const handleFilters = filter => {
    if (filter === 'All' || filter === 'Alles') {
      setFilteredItems(investments)
      setActiveFilter([filter])
    } else if (activeFilter.includes(filter)) {
      const afterFilters = activeFilter.filter(item => item !== filter)
      if (afterFilters.length === 0) {
        setFilteredItems(investments)
        setActiveFilter([allText])
      } else {
        setActiveFilter(afterFilters)
        let filtered = investments.filter(
          ({ status, city }) =>
            afterFilters.includes(city) || afterFilters.includes(status)
        )

        if (
          filtered.some(inv => afterFilters.includes(inv.city)) &&
          filtered.some(inv => afterFilters.includes(inv.status))
        ) {
          filtered = filtered.filter(
            ({ city, status }) =>
              afterFilters.includes(city) && afterFilters.includes(status)
          )
        }
        setFilteredItems(filtered)
      }
    } else {
      const newFilters = [...activeFilter, filter].filter(
        currFilter =>
          !currFilter.includes('All') || !currFilter.includes('Alles')
      )

      let filtered = investments.filter(
        ({ status, city }) =>
          city === filter ||
          status === filter ||
          activeFilter.includes(city) ||
          activeFilter.includes(status)
      )
      if (
        filtered.some(inv => newFilters.includes(inv.city)) &&
        filtered.some(inv => newFilters.includes(inv.status))
      ) {
        filtered = filtered.filter(
          ({ city, status }) =>
            newFilters.includes(city) && newFilters.includes(status)
        )
      }

      setFilteredItems(filtered)
      if (activeFilter.includes('All') || activeFilter.includes('Alles'))
        setActiveFilter([filter])
      else setActiveFilter(newFilters)
    }
  }

  const allCities = investments
    .map(({ city }) => city)
    .filter(city => city)
    .sort()

  const allStatuses = investments
    .map(({ status }) => status)
    .filter(status => status)
    .reverse()

  const filters = [
    allText,
    ...[...new Set(allCities)],
    ...[...new Set(allStatuses)],
  ]

  return (
    <Wrapper>
      <Filters items={filters.length}>
        {filters.map(filter => (
          <Button
            key={filter}
            onClick={() => handleFilters(filter)}
            isActive={activeFilter.includes(filter)}
            outline
          >
            {filter}
          </Button>
        ))}
      </Filters>
      <>
        {filteredItems.length === 0 ? (
          <Text>
            {lang === 'DE' && 'Es gibt keine Ergebnisse.'}
            {lang === 'EN' && 'There is no results.'}
          </Text>
        ) : (
          <ItemsWrapper>
            {filteredItems.map(
              ({
                id,
                image,
                imageAlt,
                city,
                district,
                name,
                description,
                link,
                etiquete,
              }) => (
                <SingleInvestment
                  key={id}
                  image={image}
                  imageAlt={imageAlt}
                  localization={district ? `${city} - ${district}` : city}
                  name={name}
                  description={description}
                  link={link}
                  etiquete={etiquete}
                />
              )
            )}
          </ItemsWrapper>
        )}
      </>
    </Wrapper>
  )
}

Investments.propTypes = {
  investments: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Investments
