import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Heading from 'components/shared/Heading'
import LazyImage from 'components/shared/LazyImage'
import Icon from 'components/shared/Icon'
import useMedia from 'hooks/useMedia'
import dotsSvg from 'assets/icons/illu_about.svg'

const Wrapper = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
  }
`

const IconWrapper = styled.div`
  display: none;
  position: absolute;
  width: 80px;
  top: 15%;
  left: -10%;
  transform: translateY(-15%);
  pointer-events: none;
  ${({ theme }) => theme.mq.xl} {
    display: block;
  }
`

const Content = styled.div`
  position: relative;
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  order: 1;
  ${({ theme }) => theme.mq.s} {
    padding: 60px;
    border: 1px solid ${({ theme }) => theme.white};
  }
  ${({ theme }) => theme.mq.xl} {
    order: ${({ right }) => (right ? '1' : '-1')};
  }
`

const Description = styled.p`
  padding-top: 30px;
  white-space: pre-wrap;
  font-size: 1.7rem;
  line-height: 23px;
  ${({ theme }) => theme.mq.s} {
    padding-top: 40px;
  }
`

const Teaser = styled.div`
  padding-top: 30px;
  margin-top: auto;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.superBold};
  max-width: 440px;
`

const Number = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xlg};
  color: ${({ theme }) => theme.colors.yellow};
  ${({ theme }) => theme.mq.xs} {
    font-size: ${({ theme }) => theme.fontSize.xxl};
  }
`

const Text = styled.h4`
  margin-left: 14px;
  font-size: ${({ theme }) => theme.fontSize.md};
  text-transform: uppercase;
  line-height: 24px;
  ${({ theme }) => theme.mq.xs} {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Card = ({ header, image, imageAlt, description, teaser, right }) => {
  const matchesS = useMedia('(min-width: 576px)')
  return (
    <Wrapper>
      {header.title.toLowerCase().includes('about') && (
        <IconWrapper>
          <Icon full src={dotsSvg} alt="dots" />
        </IconWrapper>
      )}
      <Content right={right}>
        <Heading title={header.title} highlight={header.highlight} />
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        {teaser.text && (
          <Teaser>
            <Number>{teaser.number}</Number>
            <Text>{teaser.text}</Text>
          </Teaser>
        )}
      </Content>
      <ImageWrapper>
        <LazyImage
          height={matchesS ? 500 : 300}
          cover
          src={image}
          alt={imageAlt}
        />
      </ImageWrapper>
    </Wrapper>
  )
}

Card.propTypes = {
  header: PropTypes.objectOf(PropTypes.string).isRequired,
  teaser: PropTypes.objectOf(PropTypes.string).isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  right: PropTypes.bool,
}

Card.defaultProps = {
  right: false,
}

export default Card
