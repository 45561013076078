import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Content from 'components/shared/Content'
import Card from 'components/About/Card'

const Wrapper = styled.section`
  position: relative;
  display: flex;
  padding: 20px 0 0;
  flex-direction: column;
  align-items: center;
  background: url(${({ bgImage }) => bgImage}) no-repeat center;
  background-size: cover;
  z-index: 2;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background-color: rgba(50, 50, 50, 0.9);
  }
  ${({ theme }) => theme.mq.md} {
    padding: 80px 0 0;
  }
`

const StyledContent = styled(Content)`
  width: 100%;
  z-index: 4;
  padding: 20px inherit;
  overflow: visible;
  ${({ theme }) => theme.mq.md} {
    padding: 40px inherit;
    & > * {
      margin-bottom: 80px;
    }
  }
`

const About = ({ cards, background }) => (
  <Wrapper bgImage={background} id="about">
    <StyledContent>
      {cards.map(
        ({ id, header, teaser, description, image, imageAlt }, index) => (
          <Card
            key={id}
            header={header}
            teaser={teaser}
            description={description}
            image={image}
            imageAlt={imageAlt}
            right={Boolean(index % 2)}
          />
        )
      )}
    </StyledContent>
  </Wrapper>
)

About.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  background: PropTypes.string.isRequired,
}

export default About
