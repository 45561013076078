import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Content from 'components/shared/Content'
import Heading from 'components/shared/Heading'
import germanyMap from 'assets/icons/map.svg'
import pinIcon from 'assets/icons/pin.svg'

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 620px;
  background-color: ${({ theme }) => theme.colors.gray200};
  overflow: hidden;
  ${({ theme }) => theme.mq.md} {
    height: 680px;
  }
`

const Centered = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  ${({ theme }) => theme.mq.lg} {
    top: 0%;
    transform: translate(-50%, 0%);
  }
`

const BackgroundMap = styled.div`
  position: relative;
  margin: 0 auto;
  width: 600px;
  height: 600px;
  background: url(${germanyMap}) no-repeat center;
  ${({ theme }) => theme.mq.md} {
    width: 850px;
    height: 850px;
    background-size: 100%;
    background-position: 50% 36%;
  }
`

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  max-width: 320px;
  padding: 5px 0;
  ${({ theme }) => theme.mq.md} {
    padding: 30px 0;
  }
  ${({ theme }) => theme.mq.xl} {
    max-width: none;
  }
`

const City = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.gray};
    border-radius: 50%;
    margin-right: 5px;
    ${({ theme }) => theme.mq.md} {
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }
  }
  ${({ isEstate }) =>
    isEstate &&
    css`
      font-weight: ${({ theme }) => theme.superBold};
      &:before {
        width: 22px;
        height: 22px;
        background: url(${pinIcon}) no-repeat center;
        ${({ theme }) => theme.mq.md} {
          width: 36px;
          height: 36px;
        }
      }
    `} 
  ${({ city }) =>
    city === 'Hamburg' &&
    css`
      top: 108px;
      left: 276px;
      ${({ theme }) => theme.mq.md} {
        top: 100px;
        left: 382px;
      }
    `}
  ${({ city }) =>
    city === 'Hannover' &&
    css`
      top: 195px;
      left: 260px;
      ${({ theme }) => theme.mq.md} {
        top: 270px;
        left: 350px;
      }
    `}
  ${({ city }) =>
    city === 'Berlin' &&
    css`
      top: 180px;
      right: 150px;
      ${({ theme }) => theme.mq.md} {
        top: 230px;
        right: 80px;
      }
      &:before {
        order: 1;
        margin: 0 0 0 5px;
        ${({ theme }) => theme.mq.md} {
          margin: 0 10px 0 0;
          order: -1;
        }
      }
    `}
  ${({ city }) =>
    city === 'Dortmund' &&
    css`
      top: 265px;
      left: 155px;
      ${({ theme }) => theme.mq.md} {
        top: 400px;
        left: 150px;
      }
    `}
  ${({ city }) =>
    city === 'Leipzig' &&
    css`
      top: 275px;
      right: 190px;
      ${({ theme }) => theme.mq.md} {
        top: 420px;
        right: 145px;
      }
      &:before {
        order: 1;
        margin: 0 0 0 5px;
        ${({ theme }) => theme.mq.md} {
          margin: 0 10px 0 0;
          order: -1;
        }
      }
    `}
  ${({ city }) =>
    city === 'Frankfurt' &&
    css`
      bottom: 215px;
      left: 210px;
      ${({ theme }) => theme.mq.md} {
        bottom: 225px;
        left: 260px;
      }
    `}
`

const cities = [
  'Hamburg',
  'Hannover',
  'Berlin',
  'Leipzig',
  'Dortmund',
  'Frankfurt',
]

const Map = ({ estates, title, highlight }) => (
  <Wrapper>
    <InnerWrapper>
      <Content>
        <Heading bigFrom="md" title={title} highlight={highlight} />
      </Content>
    </InnerWrapper>
    <Centered>
      <BackgroundMap>
        {cities.map(city => (
          <City key={city} isEstate={estates.includes(city)} city={city}>
            {city}
          </City>
        ))}
      </BackgroundMap>
    </Centered>
  </Wrapper>
)

Map.propTypes = {
  estates: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
}

Map.defaultProps = {
  estates: [],
}

export default Map
