/* eslint-disable react/no-danger */
import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Button from 'components/shared/Button'
import { LangContext } from 'contexts/LangContext'
import { PopupContext } from 'contexts/PopupContext'
import checkIcon from 'assets/icons/confirm.svg'
import axios from 'axios'

const StyledForm = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;
`

const Input = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 15px;
  border-radius: 4px;
  color: ${({ theme, error }) =>
    error ? theme.colors.red100 : theme.colors.dark};
  font-size: ${({ theme }) => theme.fontSize.md};
  font-family: ${({ theme }) => theme.fonts.mainFont};
  overflow: visible;
  border: 2px solid ${({ theme }) => theme.colors.white};
  transition: 0.3s;
  ${({ theme }) => theme.mq.s} {
    padding: 15px 35px;
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
  &:focus {
    border-color: rgba(50, 50, 50, 0.2);
  }
`

const Textarea = styled(Input)`
  flex: 1;
  resize: none;
  font-family: inherit;
  margin-bottom: 0;
  min-height: 220px;
`

const StyledButton = styled(Button)`
  position: absolute;
  left: 50%;
  bottom: -70px;
  transform: translateX(-50%);
  max-width: 200px;
`

const MessageWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: ${({ borderColor }) =>
    borderColor ? 'translate(-50%, 0)' : 'translate(-50%, 30%)'};
  background: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.gray};
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 30px -8px rgba(0, 0, 0, 0.2);
  z-index: 2;
  width: 300px;
  transition: 0.2s;
  opacity: ${({ borderColor }) => (borderColor ? '1' : '0')};
  visibility: ${({ borderColor }) => (borderColor ? 'visible' : 'hidden')};
  ${({ borderColor }) =>
    borderColor === 'red' &&
    css`
      border-color: ${({ theme }) => theme.colors.red};
    `}
  ${({ borderColor }) =>
    borderColor === 'green' &&
    css`
      border-color: ${({ theme }) => theme.colors.green};
    `}
`

const MessageContent = styled.span`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.md};
  line-height: 26px;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  min-height: 25px;
  position: relative;
  padding-left: 40px;
  margin: 15px 0;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.light};
  font-size: ${({ theme }) => theme.fontSize.xs};
  text-align: justify;
  user-select: none;
  line-height: 18px;
  ${({ theme }) => theme.mq.xs} {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.red : theme.colors.dark};
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 4px;
    width: 15px;
    height: 15px;
    background: url(${checkIcon}) no-repeat center;
  }
`

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`

const LabelText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  a {
    color: #4287f5;
    text-decoration: none;
    transition: 0.3s;
    &:hover {
      color: #72a1ed;
    }
  }
  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
    `}
`

const LabelBtn = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: ${({ theme }) => theme.fonts.mainfont};
  text-transform: uppercase;
  cursor: pointer;
  align-self: flex-start;
  padding: 2px 0 2px 10px;
  margin-left: auto;
`

const PrivacyLink = styled.a`
  color: #4287f5;
  text-decoration: none;
  transition: 0.3s;
  &:hover {
    color: #72a1ed;
  }
`

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g

const inputText = {
  EN: {
    button: 'Send',
    name: 'NAME AND SURNAME*',
    email: 'E-MAIL ADDRESS*',
    phone: 'PHONE NUMBER*',
    message: 'TYPE YOUR MESSAGE',
    checkbox:
      'By submitting this form, you agree that your information from the contact form will be collected and processed to answer your request. The data will be deleted after the processing of your request. You can revoke your consent at any time for the future by sending an e-mail to <a href="mailto:office@victoriawohnungsbau.de">office@victoriawohnungsbau.de</a>. Detailed information on handling user data can be found in our',
    checkboxBtnMore: 'More',
    checkboxBtnLess: 'Less',
    privacyPolicy: 'privacy policy',
  },
  DE: {
    button: 'Senden',
    name: 'VOR UND NACHNAME*',
    email: 'E-MAIL ADRESSE*',
    phone: 'TELEFONNUMMER*',
    message: 'GEBEN SIE IHRE NACHRICHT EIN',
    checkbox:
      'Mit dem Absenden dieses Formulars erklären Sie sich damit einverstanden, dass Ihre Angaben aus dem Kontaktformular zur Beantwortung Ihrer Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an <a href="mailto:office@victoriawohnungsbau.de">office@victoriawohnungsbau.de</a> widerrufen. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer',
    checkboxBtnMore: 'Mehr',
    checkboxBtnLess: 'Weniger',
    privacyPolicy: 'Datenschutzerklärung',
  },
}

const errorMessages = {
  EN: {
    name: 'Your name is invalid!',
    nameReq: 'Your name is required!',
    email: 'Email address is invalid!',
    emailReq: 'Email address is required!',
    phone: 'Phone number is not valid!',
    phoneReq: 'Phone number is required!',
    defaultErr: 'Something went wrong!',
    success: 'Your message has been sent successfully!',
    terms: 'Accept terms & conditions!',
  },
  DE: {
    name: 'Ihr Name ist ungültig!',
    nameReq: 'Ihr Name ist erfolderlich!',
    email: 'E-Mail Adresse ist ungültig!',
    emailReq: 'E-Mail Adresse ist erforderlich!',
    phone: 'Telefonnummer ist ungültig!',
    phoneReq: 'Telefonnummer ist erforderlich!',
    defaultErr: 'Etwas ist schief gelaufen!',
    success: 'Ihre Nachricht wurde erfolgreich gesendet!',
    terms: 'Die Bedingungen annehmen!',
  },
}

const ContactForm = ({ source }) => {
  const [isTermCollapsed, setIsTermCollapsed] = useState(true)
  const [formMessage, setFormMessage] = useState({ text: '', color: '' })
  const { lang } = useContext(LangContext)
  const { showPrivacyPolicy } = useContext(PopupContext)

  const contactSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, errorMessages[lang].name)
      .required(errorMessages[lang].nameReq),
    email: Yup.string()
      .email(errorMessages[lang].email)
      .required(errorMessages[lang].emailReq),
    phone: Yup.string()
      .matches(phoneRegExp, errorMessages[lang].phone)
      .required(errorMessages[lang].phoneReq),
    terms: Yup.bool().oneOf([true], errorMessages[lang].terms),
  })

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        message: '',
        terms: false,
      }}
      validationSchema={contactSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(
        { name, email, phone, message, terms },
        { setSubmitting, resetForm }
      ) => {
        setSubmitting(true)
        const sendEmail = async () => {
          try {
            await axios.get(
              `https://cors-anywhere.indigital.guru/https://victoriade.indigital.pl/wp-content/themes/victoria-wohnungsbau/inc/mailer.php?name=${name}&email=${email}&phone=${phone}&message=${message}&source=${source}&terms=${terms}`
            )
            setFormMessage({
              text: errorMessages[lang].success,
              color: 'green',
            })

            setTimeout(() => {
              setFormMessage(({ text }) => setFormMessage({ text, color: '' }))
              setSubmitting(false)
              resetForm()
            }, 2500)
          } catch (err) {
            setFormMessage({
              text: errorMessages[lang].defaultErr,
              color: 'red',
            })

            setTimeout(() => {
              setSubmitting(false)
              setFormMessage(({ text }) => setFormMessage({ text, color: '' }))
            }, 1500)
          }
        }
        sendEmail()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setErrors,
        isSubmitting,
      }) => (
        <StyledForm onSubmit={handleSubmit} autoComplete="off">
          <Input
            id="name"
            placeholder={inputText[lang].name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.name}
            onFocus={() => setErrors({ ...errors, name: '' })}
            value={errors.name || values.name}
            touched={touched.name}
            errors={errors.name}
          />
          <Input
            id="email"
            placeholder={inputText[lang].email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email}
            onFocus={() => setErrors({ ...errors, email: '' })}
            value={errors.email || values.email}
            touched={touched.email}
            errors={errors.email}
          />
          <Input
            id="phone"
            placeholder={inputText[lang].phone}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.phone}
            onFocus={() => setErrors({ ...errors, phone: '' })}
            value={errors.phone || values.phone}
            touched={touched.phone}
            errors={errors.phone}
          />
          <Textarea
            as="textarea"
            id="message"
            placeholder={inputText[lang].message}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.message}
            onFocus={() => setErrors({ ...errors, message: '' })}
            value={errors.message || values.message}
            touched={touched.message}
            errors={errors.message}
          />
          <Label htmlFor="terms">
            <LabelText expanded={!isTermCollapsed}>
              <span
                dangerouslySetInnerHTML={{
                  __html: inputText[lang].checkbox,
                }}
              />
              <PrivacyLink
                onClick={showPrivacyPolicy}
              >{` ${inputText[lang].privacyPolicy}.`}</PrivacyLink>
            </LabelText>
            <LabelBtn
              type="button"
              onClick={() => setIsTermCollapsed(!isTermCollapsed)}
            >
              {isTermCollapsed
                ? inputText[lang].checkboxBtnMore
                : inputText[lang].checkboxBtnLess}
            </LabelBtn>
            <Checkbox
              id="terms"
              name="terms"
              type="checkbox"
              onChange={() => {
                setFieldValue('terms', !values.terms)
                setErrors({ ...errors, terms: '' })
              }}
              checked={values.terms}
            />
            <Checkmark error={errors.terms} />
          </Label>
          <MessageWrapper borderColor={formMessage.color}>
            <MessageContent>{formMessage.text}</MessageContent>
          </MessageWrapper>
          <StyledButton submit red disabled={isSubmitting} type="submit">
            {isSubmitting ? '' : inputText[lang].button}
          </StyledButton>
        </StyledForm>
      )}
    </Formik>
  )
}

ContactForm.propTypes = {
  source: PropTypes.string.isRequired,
}

export default ContactForm
