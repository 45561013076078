import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Heading from 'components/shared/Heading'
import Content from 'components/shared/Content'
import Section from 'components/shared/Section'
import ContactCard from 'components/Contact/ContactCard'
import ContactForm from 'components/Contact/ContactForm'

const Wrapper = styled(Section)`
  background: ${({ theme }) => theme.colors.gray200};
  padding-bottom: 0 !important;
`

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  width: 100%;
  padding: ${({ single }) => (single ? '40px 0 0' : '30px 0 70px')};
  ${({ theme }) => theme.mq.md} {
    padding: ${({ single }) => (single ? '60px 0 0' : '60px 0 90px')};
  }
  ${({ theme }) => theme.mq.xl} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Contact = ({ source, title, mainLocalization, subLocalizations }) => (
  <Wrapper id="contact">
    <Content>
      <Heading big title={title} />
      <InnerWrapper single={subLocalizations.length === 0}>
        <ContactCard
          city={mainLocalization.city || ''}
          name={mainLocalization.name}
          image={mainLocalization.image}
          imageAlt={mainLocalization.imageAlt}
          localization={mainLocalization.localization}
          email={mainLocalization.email}
          phone={mainLocalization.phone}
        />
        <ContactForm source={source} />
      </InnerWrapper>
      {subLocalizations && (
        <InnerWrapper>
          {subLocalizations.map(
            ({
              id,
              city,
              name,
              image,
              imageAlt,
              localization,
              email,
              phone,
            }) => (
              <ContactCard
                key={id}
                city={city}
                name={name}
                image={image}
                imageAlt={imageAlt}
                localization={localization}
                email={email}
                phone={phone}
              />
            )
          )}
        </InnerWrapper>
      )}
    </Content>
  </Wrapper>
)

Contact.propTypes = {
  mainLocalization: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object])
  ).isRequired,
  title: PropTypes.string,
  subLocalizations: PropTypes.arrayOf(PropTypes.object),
  source: PropTypes.string,
}

Contact.defaultProps = {
  title: 'Contact us',
  subLocalizations: [],
  source: 'Hauptseite',
}

export default Contact
