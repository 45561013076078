import React, { useContext } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import LazyImage from 'components/shared/LazyImage'
import Icon from 'components/shared/Icon'
import Button from 'components/shared/Button'
import logoIcon from 'assets/icons/logo.svg'
import pinIcon from 'assets/icons/pin.svg'
import { LangContext } from 'contexts/LangContext'

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 20px;
  ${({ theme }) => theme.mq.s} {
    max-width: 400px;
  }
`

const ImageWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 280px;
  overflow: hidden;
  background: #ddd;
  border-radius: 8px;
  ${({ scaleonhover }) =>
    scaleonhover &&
    css`
      &:hover {
        & > ${LazyImage} {
          transform: scale(1.1);
        }
      }
    `}
`

const Badge = styled.div`
  position: absolute;
  top: 30px;
  right: -2px;
  padding: 12px 16px;
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.red};
  text-transform: uppercase;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0 0;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.lg};
  flex-grow: 1;
  ${({ theme }) => theme.mq.s} {
    padding: 20px 0 0;
  }
`

const Localization = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  text-align: left;
  & > div {
    margin: 0 10px;
  }
`

const Name = styled.h3`
  padding: 14px 0;
  text-transform: uppercase;
  font-size: 2.6rem;
  font-weight: ${({ theme }) => theme.superBold};
  ${({ theme }) => theme.mq.lg} {
    font-size: ${({ theme }) => theme.fontSize.xl};
    padding: 22px 0;
  }
`

const Description = styled.p`
  padding: 0 15px;
  line-height: 2.4rem;
  white-space: pre-wrap;
  flex-grow: inherit;
`

const StyledButton = styled(Button)`
  margin-top: 10px;
  max-width: 200px;
  padding: 14px 0;
  ${({ theme }) => theme.mq.s} {
    margin-top: 10px;
    padding: 16px 0;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-top: 0;
  }
`

const SingleInvestment = ({
  image,
  imageAlt,
  localization,
  name,
  description,
  link,
  type,
  etiquete,
}) => {
  const { lang } = useContext(LangContext)

  return (
    <Wrapper>
      <ImageWrapper
        scaleonhover={link}
        as={!link && 'div'}
        to={lang === 'EN' ? `/en${link}` : `${link}`}
      >
        {image ? (
          <>
            <LazyImage height={300} src={image} alt={imageAlt || name} cover />
            {etiquete && <Badge>{etiquete}</Badge>}
          </>
        ) : (
          <Icon src={logoIcon} size={200} alt="logo" />
        )}
      </ImageWrapper>
      <Content>
        <Localization>
          <Icon src={pinIcon} size={30} alt="pin" />
          {localization}
        </Localization>
        <Name>{name}</Name>
        {description && (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </Content>
      {link && (
        <StyledButton
          secondary={type !== 'investment' ? 1 : 0}
          as={Link}
          to={lang === 'EN' ? `/en${link}` : `${link}`}
        >
          {lang === 'EN' && 'Visit'}
          {lang === 'DE' && 'Mehr'}
        </StyledButton>
      )}
    </Wrapper>
  )
}

SingleInvestment.propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string,
  etiquete: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  localization: PropTypes.string.isRequired,
  link: PropTypes.string,
}

SingleInvestment.defaultProps = {
  imageAlt: '',
  etiquete: '',
  type: 'investment',
  link: '',
}

export default SingleInvestment
