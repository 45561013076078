import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Heading from 'components/shared/Heading'
import Content from 'components/shared/Content'
import Icon from 'components/shared/Icon'
import Button from 'components/shared/Button'
import { LangContext } from 'contexts/LangContext'

const Wrapper = styled.section`
  background: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  padding: 10px 0 35px;
  ${({ theme }) => theme.mq.md} {
    padding: 35px 0;
  }
`

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1350px;
`

const HeadingWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mq.md} {
    display: block;
  }
`

const Description = styled.p`
  padding: 18px 0;
  line-height: 2.4rem;
  text-align: center;
  font-size: 1.8rem;
  max-width: 200px;
  font-weight: 900;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  ${({ theme }) => theme.mq.md} {
    padding: 25px 0;
  }
`

const StyledDescription = styled(Description)`
  max-width: 100%;
`

const FeaturesWrapper = styled.div`
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  width: 100%;
  ${({ theme }) => theme.mq.md} {
    gap: 30px;
    grid-template-columns: ${({ iconsLength }) =>
      `repeat(${iconsLength}, 1fr)`};
  }
`

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IconWrapper = styled.figure`
  display: block;
  width: 110px;
  height: 110px;
  ${({ theme }) => theme.mq.md} {
    width: 120px;
    height: 120px;
  }
`

const StyledButton = styled(Button)`
  max-width: 200px;
`

const Promo = () => {
  const {
    wpgraphql: { english, german },
  } = useStaticQuery(graphql`
    {
      wpgraphql {
        english: page(id: "cG9zdDoxNDc=") {
          PageHome {
            homeAboutBtnText
            homeAboutBtnUrl
            homeAboutText
            homeAboutTitle
            homeAboutList {
              homeAboutListTitle
              homeAboutListIcon {
                sourceUrl
                altText
              }
            }
          }
        }
        german: page(id: "cG9zdDo5") {
          PageHome {
            homeAboutBtnText
            homeAboutBtnUrl
            homeAboutText
            homeAboutTitle
            homeAboutList {
              homeAboutListTitle
              homeAboutListIcon {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    }
  `)

  const { lang } = useContext(LangContext)

  let currentLangData = lang === 'EN' && english
  currentLangData = lang === 'DE' ? german : currentLangData

  const {
    homeAboutBtnText,
    homeAboutBtnUrl,
    homeAboutList,
    homeAboutText,
    homeAboutTitle,
  } = currentLangData.PageHome

  return (
    <Wrapper>
      <StyledContent>
        {homeAboutTitle && (
          <HeadingWrapper>
            <Heading title={homeAboutTitle} />
          </HeadingWrapper>
        )}
        {homeAboutText && (
          <StyledDescription>{homeAboutText}</StyledDescription>
        )}
        <FeaturesWrapper iconsLength={homeAboutList.length}>
          {homeAboutList.map(({ homeAboutListIcon, homeAboutListTitle }) => (
            <Feature key={homeAboutListTitle}>
              <IconWrapper>
                <Icon
                  src={homeAboutListIcon.sourceUrl}
                  alt={homeAboutListIcon.altText || 'icon'}
                  full
                />
              </IconWrapper>
              <Description>{homeAboutListTitle}</Description>
            </Feature>
          ))}
        </FeaturesWrapper>
        {homeAboutBtnUrl && (
          <StyledButton
            dark="true"
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href={homeAboutBtnUrl}
          >
            {homeAboutBtnText}
          </StyledButton>
        )}
      </StyledContent>
    </Wrapper>
  )
}

export default Promo
